import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight, faBars, faCheck, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faSmile } from '@fortawesome/free-regular-svg-icons'
import { VueTyper } from 'vue-typer'

library.add(faLinkedin, faTimes, faCheck, faChevronRight, faArrowRight, faBars, faSmile)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vue-typer', VueTyper)

Vue.config.productionTip = false

new Vue({
  router,
  data(){
    return{
      isYellowTheme: false,
      isTransitionTextHome: false
    }
  },
  render: function (h) { return h(App) }
}).$mount('#app')
