import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Project from '@/views/Project'
import People from '@/views/People'
import KnowHow from '@/views/KnowHow'
import Contact from '@/views/Contact'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/project',
    name: 'Project',
    component: Project
  },
  {
    path: '/people',
    name: 'People',
    component: People
  },
  {
    path: '/knowhow',
    name: 'KnowHow',
    component: KnowHow
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }
]

const router = new VueRouter({
  routes
})

export default router
