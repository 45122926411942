<template>
    <nav id="header">
        <div class="banner">
            <a href="https://59pny5ne6bx.typeform.com/to/TGCUu0mw" class="text-banner"> WE ARE SEEKING INNOVATIVE MARTECH AND DIGITAL COMPANIES FOR POTENTIAL ACQUISITION OR MERGER. <a class="link" >***CLICK HERE***</a> TO FIND OUT OUR REQUIREMENTS AND TO PRESENT YOUR COMPANY. </a>
            <a href="https://59pny5ne6bx.typeform.com/to/TGCUu0mw" class="text-banner"> WE ARE SEEKING INNOVATIVE MARTECH AND DIGITAL COMPANIES FOR POTENTIAL ACQUISITION OR MERGER. <a class="link" >***CLICK HERE***</a> TO FIND OUT OUR REQUIREMENTS AND TO PRESENT YOUR COMPANY. </a>

        </div>
        <div class="normal">
            <p v-if="!$root.isYellowTheme" v-on:click="$root.isYellowTheme = !$root.isYellowTheme" class="yellow">I feel yellow <font-awesome-icon :icon="['far', 'smile']"/></p>
            <p v-else v-on:click="$root.isYellowTheme = !$root.isYellowTheme" class="black">Sorry, but I feel grey</p>
        </div>
        <div class="mobile">
            <router-link :to="'/'">
                <img :src="!$root.isYellowTheme ? require('../assets/Logo_ppk_white.svg') : require('../assets/Logo_ppk_nero.svg')" class="logoPpk">
            </router-link>
            <font-awesome-icon icon="bars" style="font-size: 25px; cursor: pointer;" v-on:click="makeVisible"/>
            <div id="menu-popup">
                <img id="menu-img" v-on:click="makeInvisible('/')" :src="!$root.isYellowTheme ? require('../assets/Logo_ppk_white.svg') : require('../assets/Logo_ppk_nero.svg')" class="logoPpk">
                <font-awesome-icon id="times" icon="times" v-on:click="makeInvisible(null)"/>
                <p v-on:click="makeInvisible('/project')">Project</p>
                <div class="separatore-gray"/>
                <p v-on:click="makeInvisible('/people')">People</p>
                <div class="separatore-gray"/>
                <p v-on:click="makeInvisible('/knowhow')">Know how</p>
                <div class="separatore-gray"/>
                <p v-on:click="makeInvisible('/contact')">Contact us</p>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    methods: {
        makeInvisible(path){
            document.getElementById("menu-popup").style = "display: none";
            if(path != null && path != this.$route.path) this.$router.push(path);
            document.getElementById("app").style = "overflow-y: auto";
        },
        makeVisible(){
            document.getElementById("menu-popup").style = "display: flex";
            document.getElementById("app").style = "overflow-y: hidden";
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/style/styles.scss';

.banner{
    display: block;
    position: fixed;
    top: 0;
    white-space: nowrap;
    background-color:$color-yellow ;
    height: 17px;
    width: 100%;
}
.text-banner{
    font-size: small;
    animation: scroll-right 30s linear infinite;
    display: inline-block;
    color: $color-black !important;
}
.link{
    color : $color-black !important;
    text-decoration: underline;
}
@keyframes scroll-right {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
#header{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p{
        text-decoration: underline;
        margin-right: 25px;
        cursor: pointer;
    }
}
#app.yellow-theme{
    .banner{
    display: block;
    position: fixed;
    top: 0;
    white-space: nowrap;
    background-color:$color-black ;
    height: 18px;
    width: 100%;
    }
    .text-banner{
        font-size: small;
        animation: scroll-right 30s linear infinite;
        display: inline-block;
        color: $color-yellow !important;
    }
    .link{
    color : $color-yellow !important;
    text-decoration: underline;
}

}
//mobile version
@media screen and (max-width: 900px){
    #header{
        display: block;
        position: fixed;
        top: 1%;
        background-color: $color-gray;
        border-bottom: 1px solid;
        z-index: 20;
    }
    .mobile{
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        padding: 10px 25px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    #menu-popup{
        display: none;
        box-sizing: border-box;
        position: fixed;
        background-color: $color-gray;
        width: 100%;
        height: 100%;
        padding: 60px 25px 25px 25px;
        top: 0;
        left: 0;
        flex-direction: column;
        justify-content: center;
        p{
            margin: 10px 0;
            text-decoration: none;
            font-size: 29px;
        }
        #times{
            position: absolute;
            top: 20px;
            right: 25px;
            font-size: 32px;
            cursor: pointer;
        }
        #menu-img{
            position: absolute;
            top: 20px;
            left: 25px;
            cursor: pointer;
        }
    }
    #app.yellow-theme{
        #header{
            background-color: $color-yellow;
        }
        #menu-popup{
            background-color: $color-yellow;
        }
    }
}
</style>
