<template>
    <div class="page">
        <div id="contact">
            <div class="contact-label">
                <p class="xx-large">Contact us</p>
                <div class="contact-label-box">
                    <div class="contact-label-box-box">
                        <div>
                            <p class="medium">Phone</p>
                            <a class="medium" href="tel:+390280896815" :class="{'muted' : !$root.isYellowTheme}">+39 02 80 896 815</a>
                        </div>
                        <br>
                        <div>
                            <p class="medium">E-mail</p>
                            <a class="medium" href="mailto:hello@ppkinnovation.com" :class="{'muted' : !$root.isYellowTheme}">hello@ppkinnovation.com</a>    
                        </div>
                        <br>
                        <div>
                            <p class="medium">Linkedin</p>
                            <a class="medium" href="https://www.linkedin.com/company/ppkinnovation/" :class="{'muted' : !$root.isYellowTheme}">@ppkinnovation</a>
                        </div>
                        <br>
                        <div>
                            <p class="medium">Whatsapp</p>
                            <a class="medium" href="https://wa.me/+393891675725" :class="{'muted' : !$root.isYellowTheme}">@+39 389 167 5725</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.page{
    height: 100%;
}
#contact, .contact-label{
    height: 100%;
    width: 100%;
}
.contact-label-box{
    p, a{
        margin: 3px 0;
    }
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
}
</style>