<template>
    <div>
        <div class="page" style="padding-right: 0;">
            <p class="xx-large">Know how</p>
            <div class="empty-space"/>
            <a v-if="!$root.isYellowTheme" href="/ppkformula/index_en.html"><span class="black bg-yellow large">Discover PPK Formula</span></a>
            <a v-else href="/ppkformula/index_en.html" ><span class="white bg-gray large">Discover PPK Formula</span></a>
            <div class="empty-space"/>
            <div class="separatore-plus"><span>+</span><p class="large">Our approach</p><span class="last">+</span></div>
            <div class="empty-space"/>
            <div id="what-ppk">
                <div>
                    <vue-typer :text="cons" id="cons" :pre-type-delay='1000' :type-delay='100' :pre-erase-delay='1500' :erase-delay='3000' 
                               @typed='onTyped' @erased='onErased' class="large" caret-animation='phase'/>
                    <div class="empty-space-half"/>
                    <div id="pro-box">
                        <p :ref="'pro'+counter" class="pro large">{{pro[counter]}}</p>
                    </div>
                </div>
            </div>
            <div class="separatore-plus"><span>+</span><span class="last">+</span></div>
            <div class="empty-space"/>
            <div id="service">
                <img :src="require('../assets/SERVICES.png')" id="img-services">
                <div class="empty-space"/>
                <div id="menu-services">
                    <div v-for="(el, index) in services" class="item" :key="index">
                        <div class="item-name" v-on:click="openItem(index)">
                            <p class="medium">{{el.name}}</p>
                        </div>
                        <div ref="itemList" class="item-list">
                            <p v-for="(sentence, sind) in el.list" class="medium" :key="sind">- {{sentence}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Footer from '@/components/Footer'
export default {
    components: {
        Footer
    },
    methods: {
        openItem(index){
            if(this.$refs.itemList[index].classList.contains("opened")){
                this.$refs.itemList[index].classList.remove("opened");
            }
            else{
                for(let i = 0; i < this.$refs.itemList.length; i++)
                   this.$refs.itemList[i].classList.remove("opened");
                this.$refs.itemList[index].classList.add("opened");
            }
        },
        onTyped(){ 
            this.$refs["pro"+this.counter].classList.add("visible");
        },
        onErased(){
            this.counter += 1;
            this.$refs["pro"+(this.counter-1)].classList.remove("visible");
            if(this.counter == this.pro.length) this.counter = 0; 
        },
    },
    data(){
        return{
            counter: 0,
            cons: [
                "We  support abstract ideas",
                "We are website developers",
                "We are e-commerce developers",
                "We are followers gatherers",
                "“We  Say “I””",
                "Sorry, we are busy!",
                "We are suppliers",
                "We are an extra cost for the company",
                "We tell customers what to do",
                "We make a logo",
                "We are gadget makers",
                "We focus on the strategy of Green washing",
                "We promote advertising campaigns for vanity metrics",
                "We express personal opinions",
                "We make collection of non-repayable loans",
                "We manage GDPR, IPr & legal conflicts",
                
            ],
            pro: [
                "We put good ideas into practice",
                "We create digital experiences",
                "We help the company increase in turnover through new channels",
                "We are loyal community builder",
                "We believe in active collaboration, sharing and teamwork",
                "Call us, we are at your disposal",
                "We are partner",
                "We are a value add for a change",
                "We show customers how to",
                "We develop the right corporate identity according with the client's vision and stakeholders",
                "We support the brand with differentiated image activity in line with its vision.",
                "We develop sustainable development strategies",
                "We promote result-oriented advertising campaigns",
                "Our opinions are based on data",
                "We find the right resources for real innovation projects",
                "We practice law by design",
                
            ],
            services: [
                {
                    name: "DESIGN",
                    list: [
                        "Business design", 
                        "Service design",
                        "Product design",
                        "Digital products",
                        "Experience design",
                        "Brand identity"
                    ],
                },
                {
                    name: "FINANCE",
                    list: [
                        "Pre-funding preparation",
                        "Raising funds for seed",
                        "Investor matching",
                        "Crowdfunding strategy",
                        "Grants and EU funding",
                        "Assistance for negotiation"
                    ],
                },
                {
                    name: "LEGAL",
                    list: [
                        "Company formation",
                        "Term sheet",
                        "Shareholders’ agreement",
                        "Intellectual property",
                        "Licensing agreement",
                        "GDPR, terms and conditions"
                    ],
                },
                {
                    name: "TECHNOLOGY",
                    list: [
                        "Minimum Viable Product",
                        "Data engineering", 
                        "Artificial Intelligence",
                        "Blockchain", 
                        "IoT",
                        "AR/VR"
                    ],
                },
                {
                    name: "MARKETING",
                    list: [
                        "Creative strategy",
                        "Campaign",
                        "Content strategy",
                        "Growth hacking",
                        "Analytics",
                        "SEO"
                    ]
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/style/styles.scss';
#what-ppk{
    width: 100%;
    padding-right: 50px;
    box-sizing: border-box;
}
#service{
    #img-services{
        filter: brightness(0) invert(1);
        height: 80px;
        width: auto;
    }
    #menu-services{
        width: 100%;
        .item-name{
            cursor: pointer;
            width: 100%;
            border-bottom: 1px solid;
            padding-bottom: 10px;
            margin: 10px 0;
        }
        .item-list{
            height: 0;
            overflow-y: hidden;
            transition: height .5s;
            p.medium{
                margin: 10px 0;
            }
        }
        .item-list.opened{
            height: 220px;
            transition: height .5s;
        }
    }
}
.separatore-plus{
    width: 100%;
    font-size: 45px;
    display: flex;
    justify-content: space-between;
    .last{
        margin-right: 50px;
    }
}
#app.yellow-theme #service img{
    filter: brightness(1);
}
//tablet version
@media screen and (max-width: 1200px){
    #img-services{
        height: 40px !important;
    }
    .item-list.opened{
        height: 200px !important;
    }
}
//mobile version
@media screen and (max-width: 900px){
    span.last{
        margin-right: 0 !important;
    }
    #what-ppk{
        padding-right: 0;
    }
    .item-list.opened{
        height: 215px !important;
    }
}
</style>
<style lang="scss">
@import '@/style/styles.scss';
#cons{
    .custom.char{
        color: $color-white;
    }
    .custom.char.selected{
        text-decoration: line-through;
        background-color: transparent;
    }
    .custom.caret{
        width: 3px;
        background-color: $color-white;
    }
}
#app.yellow-theme #cons{
    .custom.char{
        color: unset;
    }
    .custom.caret{
        background-color: $color-black;
    }
}
#pro-box{
    min-height: 125px;
    .pro{
        opacity: 0;
    }
    .pro.visible{
        opacity: 1;
        transition: opacity;
        transition-delay: 2s;  //1.5s represents delay, it is the time of pre-erase-delay, it starts after typed action
    }
}
</style>