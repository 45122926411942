<template>
  <div id="app" :class="{'yellow-theme' : $root.isYellowTheme}">
    <Header/>
    <div id="content" class="normal">
      <Main/>
      <ContactUs/>
    </div>
    <router-view class="mobile"/>
  </div>
</template>
<script>
import Header from '@/components/Header'
import Main from '@/components/Main'
import ContactUs from '@/components/ContactUs'

export default {
  components: {
    Header, Main, ContactUs
  },
  watch: {
    '$root.isYellowTheme': function(val){
      if(val) document.body.classList.add("bg-yellow");
      else document.body.classList.remove("bg-yellow");
    }
  }
}
</script>
<style lang="scss">
@import '@/style/styles.scss';
//Global style
html, body, #app{
  color: $color-white;
  border-color: $color-white;
  height: 100%;
  width: 100%;
  margin: 0;
  scroll-behavior: smooth;
  font-family: OrLemmen;
  a{
    color: $color-white;
    text-decoration: none;
  }
}
body, #app{
  background-color: $color-gray;
}
//Yellow theme
#app.yellow-theme{
  background-color: $color-yellow;
  color: $color-black;
  border-color: $color-black;
  a{
    color: $color-black; 
  }
}
#content{
  width: 100%;
  height: calc(100% - 52px);
  max-height: calc(100% - 52px);
  display: flex;
  align-items: flex-start;
}
</style>
