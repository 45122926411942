<template>
    <main>
        <section v-for="(el, index) in componentsArray" :id="'section' + index" :class="index == 0 ? 'fg-1' : 'fg-0'" :key="index" >
            <div @click="transition(index)" class="section-title" :class="{'section-title-home' : index == 0}">
                <p v-if="index != 0" class="section-title-title">{{el.segno}} {{el.title}}</p>
            </div>
            <component class="section-component" :is="el.name"/>
        </section>
    </main>
</template>
<script>
import Home from '@/views/Home'
import Project from '@/views/Project'
import People from '@/views/People'
import KnowHow from '@/views/KnowHow'
export default {
    data(){
        return{
            componentsArray: [
                {
                    title: 'Home',
                    name: 'Home',
                    segno: '+'
                },
                {
                    title: 'Projects',
                    name: 'Project',
                    segno: '+'
                },
                {
                    title: 'People',
                    name: 'People',
                    segno: '+'
                },
                {
                    title: 'Know how',
                    name: 'KnowHow',
                    segno: '+'
                }
            ]
        }
    },
    components: {
        Home, Project, People, KnowHow
    },
    methods: {
        transition(index){
            let el =  document.getElementById("section" + index);
            if(el.classList == 'fg-0'){
                let fg_01 = document.getElementsByClassName("fg-1")[0]
                if(el.id == "section0") this.$root.isTransitionTextHome = false
                if(fg_01.id == "section0"){
                    this.$root.isTransitionTextHome = true
                    document.getElementById("home").scrollTop = 0
                }
                fg_01.className = 'fg-0'
                el.className = 'fg-1'
                this.componentsArray.forEach(el => el.segno = '+')
                this.componentsArray[index].segno = '-'
            }
            else if(el.classList == 'fg-1' && el.id != "section0"){
                let prev_el = document.getElementById("section" + (index - 1))
                el.className = 'fg-0'
                prev_el.className = 'fg-1'
                if(prev_el.id == "section0") this.$root.isTransitionTextHome = false
                this.componentsArray[index].segno = '+'
                this.componentsArray[index - 1].segno = '-'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/style/styles.scss';
main{
    width: 100%;
    height: 100%;
    display: flex;
    section{
        width: 55px;
        min-width: 55px;
        height: 100%;
        max-height: 100%; 
        overflow-x: hidden;
        border-top: 2px solid;
        border-left: 2px solid;
        box-sizing: border-box;
        display: flex;
        .section-title{
            box-sizing: border-box;
            min-width: 55px;
            max-width: 55px;
            height: 100%;
            max-height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            .section-title-title{
                min-width: 150px;
                margin: 0;
                transform: rotate(-90deg) translate(-60px);
                font-size: 22px;
                cursor: pointer;
            }
        }
        .section-component{
            height: 100%;
            max-height: 100%; 
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    section#section0{
        min-width: 300px;
        border: none;
        .section-title-home{
            min-width: 300px;
            width: 300px;
            height: calc(100% - 50px/*Header height*/);
            position: absolute;
            z-index: 11;
        }
    }
    //SCROLLBAR
    .fg-1 .section-component::-webkit-scrollbar{
        width: 5px;
    }::-webkit-scrollbar-track {
        background-color: transparent;
    }::-webkit-scrollbar-thumb {
        background: rgba(128, 128, 128, 0.671);
    }::-webkit-scrollbar-thumb:hover {
        background: gray; 
    }
    .fg-0 .section-component::-webkit-scrollbar{
        width: 0;
    }
}
//tablet version
@media screen and (max-width: 1200px){
    section#section0{
        min-width: 250px !important;
        .section-title-home{
            min-width: 250px !important;
            width: 250px !important;
        }
    }
}
</style>