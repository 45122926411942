<template>
    <div id="home">
        <img :src="!$root.isYellowTheme ? require('../assets/Logo_ppk_white.svg') : require('../assets/Logo_ppk_nero.svg')" class="logoPpk">
        <div id="home-first" class="normal">
            <div id="home-text" :class="{'transition-div-text' : $root.isTransitionTextHome}">
                <p class="s-large">PPK Innovation is a design-driven consultancy company that leads innovative projects from concept to product-market fit.</p>
                <p class="s-large">PPK is aimed at individual innovators, as well as SMEs and multinational companies, research centers and universities</p>
            </div>
        </div>
        <div id="home-first-mob" class="mobile">
            <div id="home-text-mob">
                <p class="s-large">PPK Innovation is a design-driven consultancy company that leads innovative projects from concept to product-market fit.</p><br>
                <p class="s-large">PPK is aimed at individual innovators, as well as SMEs and multinational companies, research centers and universities</p>
            </div>
            <div id="changeColor">
                <p v-if="!$root.isYellowTheme" v-on:click="$root.isYellowTheme = !$root.isYellowTheme" class="yellow">I feel yellow  <font-awesome-icon :icon="['far', 'smile']"/></p>
                <p v-else v-on:click="$root.isYellowTheme = !$root.isYellowTheme" class="black">Sorry, but I feel grey</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
#home-first{
    width: 100%;
    height: 100%;
    position: relative;
    #home-text{
        max-width: 425px;
        min-width: 425px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(1) translate(-50%, -50%);
        transition: all .4s, color 0s;
        p{
            line-height: 35px;
        }
    }
    #home-text.transition-div-text{
        transform: scale(.6);
        top: 0;
        left: -60px;
        transition: all .4s, color 0s;
    }
}
.logoPpk{
    height: 45px;
    position: fixed;
    top: 50px;
    left: 25px;
    z-index: 10;
}
//tablet version
@media screen and (max-width: 1200px){
    #home-text{
        max-width: 375px !important;
        min-width: 375px !important;
    }
}
//mobile version 
@media screen and (max-width: 900px){
    #home{
        height: 100%;
    }
    .logoPpk{
        display: none;
    }
    #home-first-mob{
        padding:75px 25px;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    #changeColor{
        position: absolute;
        bottom: 10px;
        p{   
            font-size: 18px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>