<template>
    <div>
        <div class="page">
            <p class="xx-large">People</p>
            <br/>
            <p class="large">MGMT</p>
            <div class="empty-space"/>
            <div id="members">
                <div v-for="(member, index) in members" class="card-member" :key="index">
                    <a v-if="member.linkdin" :href="member.linkdin" target="_blanck"><font-awesome-icon class="i-linkedin" :icon="['fab', 'linkedin']"/></a>
                    <span v-else><font-awesome-icon class="i-linkedin" :icon="['fab', 'linkedin']"/></span>
                    <p class="s-medium">{{member.title}}</p>
                    <p class="s-medium italic">{{member.subtitle}}</p>
                    <div class="card-member-img">
                        <img v-for="(img, subindex) in member.image" :src="require('../assets/'+ img.name +'.png')" :style="img.dimension" :key="subindex">
                    </div>
                </div>
            </div>
            <div class="empty-space"/>
            <div class="separatore"/>
            <div class="empty-space"/>
            <p class="large">PPK’S</p>
            <div class="empty-space"/>
            <div id="ppks">
                <div v-for="(el, index) in ppks" :key="index">
                    <p class="medium">{{el.name}}</p>
                    <p class="medium italic">{{el.role}}</p>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Footer from '@/components/Footer'
export default {
    components: {
        Footer
    },
    data(){
        return{
            members: [
                {
                    title: "FABRIZIO",
                    subtitle: "Innovation Designer",
                    image: [
                        {
                            name: "CEO",
                            dimension: ""
                        }
                    ],
                    linkdin: "https://www.linkedin.com/in/fabriziolipani/"
                },
                {
                    title: "ANTONINO",
                    subtitle: "Senior Advisor",
                    image: [
                        {
                            name: "MANAGING_DIRECTOR",
                            dimension: "height: 43.34px;"
                        }
                    ],
                    linkdin: "https://www.linkedin.com/in/antonino-cilluffo/"
                },
                {
                    title: "CARLO",
                    subtitle: "COO",
                    image: [
                        {
                            name: "COO",
                            dimension: ""
                        }
                    ],
                    linkdin: "https://www.linkedin.com/in/carlo-giambra-4036349a/"
                },
                {
                    title: "VINCENZO",
                    subtitle: "Digital Strategist",
                    image: [
                        {
                            name: "MARKETING",
                            dimension: ""
                        }
                    ],
                    linkdin: "https://www.linkedin.com/in/vincenzograci/?originalSubdomain=it"
                },
                {
                    title: "VIRGINIA",
                    subtitle: "Head of Design",
                    image: [
                        {
                            name: "DESIGN",
                            dimension: ""
                        }
                    ],
                    linkdin: "https://www.linkedin.com/in/virginia-norrito-7935a597/"
                },
                {
                    title: "ELIA",
                    subtitle: "Head of Technology",
                    image: [
                        {
                            name: "TECHNOLOGY",
                            dimension: ""
                        }
                    ],
                    linkdin: ""
                },
                {
                    title: "ANGELISA",
                    subtitle: "Head of Legal",
                    image: [
                        {
                            name: "LEGAL",
                            dimension: ""
                        }
                    ],
                    linkdin: "https://www.linkedin.com/in/angelisa-castronovo-41459915/"
                },
                {
                    title: "FEDERICO",
                    subtitle: "Strategic Advisor",
                    image: [
                        {
                            name: "GROWTH",
                            dimension: ""
                        }
                    ],
                    linkdin: "https://www.linkedin.com/in/federicopuccioni/"
                },
                {
                    title: "LINA",
                    subtitle: "Business Designer",
                    image: [
                        {
                            name: "DESIGN",
                            dimension: ""
                        }
                    ],
                    linkdin: "https://www.linkedin.com/in/linavargasvargas/"
                }
            ],
            ppks: [
                {
                    name: "ANDREA",
                    role: "Project Manager"
                },
                {
                    name: "DAVIDE",
                    role: "Graphic Designer"
                },
                {
                    name: "GIORGIO",
                    role: "Growth & Product manager"
                },
                {
                    name: "GIULIA",
                    role: "Marketing assistant"
                },
                {
                    name: "ANTONIO",
                    role: "VR & AR advisor"
                },
                {
                    name: "SIMONA",
                    role: "Accountant"
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
#members{
    .card-member{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .i-linkedin{
            font-size: 25px;
            margin-bottom: 15px;
        }
        .s-medium{
            margin-bottom: 5px;
        }
        .card-member-img{
            width: 100%;
            height: 125px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                filter: brightness(0) invert(1);
                height: 80px;
                width: auto;
            }
        }
    }
}
#members, #ppks{ 
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 70px;
    row-gap: 80px;
}
#app.yellow-theme .card-member-img img{
    filter: brightness(100%);
}
//mobile version
@media screen and (max-width: 900px){
    #members, #ppks{ 
        grid-template-columns: 1fr;
        row-gap: 40px;
    }
}
</style>